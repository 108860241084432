/*
 * @copyright aromicon GmbH (www.aromicon.de)
 * @module Aromicon_Baseprice
 */
.aromicon-baseprice-category {
  font-family: 'Montserrat';
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  padding-left: 5px; }

.aromicon-baseprice-product {
  display: table-row;
  font-family: 'Montserrat';
  font-size: 10px;
  font-weight: 400;
  font-style: normal; }

.checkout-cart-index .page-main .columns .column.main .cart-container .form-cart .table-wrapper .cart.item .item-info .col.item .product-item-details .product-item-name {
  min-height: unset !important; }

.product-item-details {
  display: inline-grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr; }
  .product-item-details .product-item-name {
    grid-row: 1;
    grid-column: 1/3; }
  .product-item-details .price-box {
    grid-row: 2; }
  .product-item-details .aromicon-baseprice-category {
    grid-row: 2;
    align-self: center; }
  .product-item-details .price-details {
    grid-row: 3;
    grid-column: 1/3; }
  .product-item-details .product-item-inner {
    grid-row: 4;
    grid-column: 1/3; }

.product-info-price {
  display: inline-grid !important;
  grid-template-rows: 1fr auto;
  grid-template-columns: auto 1fr; }
  .product-info-price .price-box {
    grid-row: 1; }
  .product-info-price .price-details {
    grid-row: 2;
    grid-column: 1/3; }
  .product-info-price .aromicon-baseprice-product {
    grid-row: 1;
    grid-column: 2/3;
    align-self: center; }

.catalog-product-view .product-info-main .product.attribute.delivery_time {
  display: inline-table !important; }

.productslider .details {
  display: inline-grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr auto auto auto; }
  .productslider .details .title {
    grid-row: 1;
    grid-column: 1/3; }
  .productslider .details .price {
    grid-row: 2; }
  .productslider .details .aromicon-baseprice-product {
    grid-row: 2;
    align-self: center;
    padding-left: 5px;
    margin-bottom: 10px; }
  .productslider .details .tax {
    grid-row: 3;
    grid-column: 1/3; }
  .productslider .details .action {
    grid-row: 4;
    grid-column: 1/3; }

.products-grid.wishlist .product-item-info {
  display: inline-grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr auto auto auto; }
  .products-grid.wishlist .product-item-info .product-item-photo {
    grid-row: 1;
    grid-column: 1/3; }
  .products-grid.wishlist .product-item-info .product-item-name {
    grid-row: 2;
    grid-column: 1/3; }
  .products-grid.wishlist .product-item-info .price-box {
    grid-row: 3; }
  .products-grid.wishlist .product-item-info .aromicon-baseprice-category {
    grid-row: 3;
    align-self: center;
    margin-bottom: 10px; }
  .products-grid.wishlist .product-item-info .price-details {
    grid-row: 4;
    grid-column: 1/3; }
  .products-grid.wishlist .product-item-info .product-item-inner {
    grid-row: 5;
    grid-column: 1/3; }
